import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NoData from '../main/noData';
import { Pagination } from './Pagination';
import { CiSearch } from "react-icons/ci";
import { FaSliders } from "react-icons/fa6";
import { IoIosArrowUp } from "react-icons/io";
import MultipleFileUpload from '../fileupload/MultipleFileUpload';
import { IoIosArrowDown } from "react-icons/io";
import baseApiService from '../../services/baseApiService';


const List = ({ isSidebarOpen }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const tableWidth = isSidebarOpen ? 'calc(100% - 256px)' : '100%';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [copyStatus, setCopyStatus] = useState('');
  const [copyStatuses, setCopyStatuses] = useState({});



  const isDataEmpty = filteredData.length === 0;
  const [sortColumn, setSortColumn] = useState('fileUploadTime');
  const [sortOrder, setSortOrder] = useState('desc');
  const sortData = (column) => {
    let sortedData = [...data];

    switch (column) {
      case 'fileName':
        sortedData.sort((a, b) => sortOrder === 'asc' ? a.fileName.localeCompare(b.fileName) : b.fileName.localeCompare(a.fileName));
        break;
      case 'fileSize':
        sortedData.sort((a, b) => sortOrder === 'asc' ? a.fileSize - b.fileSize : b.fileSize - a.fileSize);
        break;
      case 'fileUploadTime':
        sortedData.sort((a, b) => {
          const dateA = new Date(a.fileUploadTime);
          const dateB = new Date(b.fileUploadTime);
          return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
        });
        break;
      default:
        break;
    }

    setFilteredData(sortedData);
  };

  const toggleSortOrder = () => {
    setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      toggleSortOrder();
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
    sortData(column);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTUxMiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9lbWFpbGFkZHJlc3MiOiJtZXJpdHNobTE5QGdtYWlsLmNvbSIsImV4cCI6MTcxOTM4MTg0MX0.diJTHMkwA2g6gaR2MZ4Ya9Y6_6mAKHSYx-hQtf0gk_YuhXPgA8lHFHP8tRriKSCLJClqQiCHqCjQliI2o-t_Vg";
      try {
        setIsLoading(true);
        const response = await baseApiService.get(`${process.env.REACT_APP_API_BASE_URL}v1/FileUpload`);
        setData(response.data.data || [response.data]);
        setFilteredData(response.data.data || [response.data]);
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [currentPage]);

  const sortDataByUploadTime = () => {
    const sortedData = [...data].sort((a, b) => {
      const dateA = new Date(a.fileUploadTime);
      const dateB = new Date(b.fileUploadTime);
      return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    });
    setFilteredData(sortedData);
  };


  useEffect(() => {
    if (!searchQuery) {
      sortDataByUploadTime();
    }
  }, [currentPage, data, searchQuery]);

  useEffect(() => {
    sortDataByUploadTime();
  }, []); // This useEffect runs only once on component mount

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handleSearch = () => {
    if (searchQuery === '') {
      // Reset filtered data if search query is empty
      setFilteredData(data);
      return;
    }

    const formattedQuery = searchQuery.toLowerCase();

    const filtered = data.filter(item => {
      const fileName = item.fileName || '';
      const formattedFileName = fileName.toLowerCase();

      const uploadedBy = item.dataSourcedByPerson || '';
      const formattedUploadedBy = uploadedBy.toLowerCase();

      const fileSize = item.fileSize.toString() || '';
      const formattedFileSize = fileSize.toLowerCase();

      const uploadTime = formatUploadTime(item.fileUploadTime) || '';
      const formattedUploadTime = uploadTime.toLowerCase();

      const dataSourceLink = item.dataSourceLink || '';
      const formattedDataSourceLink = dataSourceLink.toLowerCase();

      // Check if any field matches the search query
      return (
        formattedFileName.includes(formattedQuery) ||
        formattedUploadedBy.includes(formattedQuery) ||
        formattedFileSize.includes(formattedQuery) ||
        formattedUploadTime.includes(formattedQuery) ||
        formattedDataSourceLink.includes(formattedQuery)
      );
    });

    setFilteredData(filtered);
  };





  const handleSearchIconClick = () => {
    handleSearch();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  const formatUploadTime = (timestamp) => {
    const date = new Date(timestamp);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    let hours = date.getHours();
    const meridiem = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds} ${meridiem}`;
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState);
  };
  const copyToClipboard = (dataSourceLink, index) => {
    const el = document.createElement('textarea');
    el.value = dataSourceLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    // Update copy status for the specific row
    setCopyStatuses(prevStatuses => ({
      ...prevStatuses,
      [index]: 'Copied'
    }));

    // Reset status after 3 seconds
    setTimeout(() => {
      setCopyStatuses(prevStatuses => ({
        ...prevStatuses,
        [index]: ''
      }));
    }, 1000);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div role="status">
          <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#36678E]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }


  return (
    <>
      {isModalOpen && (
        <div className="fixed inset-0 z-10 flex items-center justify-center backdrop-blur p-2 overflow-hidden">
          <div className="relative">
            <button onClick={closeModal} className="absolute top-2 right-2 text-red-600 hover:text-[#8e3636b3] focus:outline-none">
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <MultipleFileUpload onClose={closeModal} />
          </div>
        </div>
      )}


      {data.length === 0 ? (
        <NoData className="font-Inter" />
      ) : (

        <>
          {/* Search Section */}
          <div className={`w-full md:w-${tableWidth} h-[50px] px-2 md:px-4  flex flex-col md:flex-row justify-between items-center header font-Inter`}>
            <div className='flex flex-grow justify-between gap-[12px] w-full'>
              <div className='flex items-center justify-between gap-[12px] flex-grow'>
                <span className='font-semibold text-xl text-[#18191F] whitespace-nowrap'>File Repository</span>
                <button onClick={() => setIsModalOpen(true)} className='w-[81px] h-[34px] rounded-[6px] bg-[#36678E] shadow-md'>
                  <span className='w-[55px] h-[16px] font-medium text-[14px] text-white'>Add File</span>
                </button>
              </div>
              <div className='flex items-center gap-[16px] justify-end w-full md:w-[350px] overflow-hidden'>
                <div className="flex items-center justify-between rounded-[6px] border-[0.5px] border-[#D1D5DB] gap-[8px] px-[12px] w-full">
                  <div className="flex items-center gap-[10px] w-full">
                    <input
                      type="text"
                      placeholder="Search"
                      className="w-full md:w-[240px] bg-[#f8f8f8] h-[34px] border-0 font-light text-sm outline-none placeholder-gray-500 font-Inter"
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        handleSearch();
                      }}
                      onKeyPress={handleKeyPress}
                    />
                    <div className="w-[18px] h-[18px]" onClick={handleSearchIconClick}>
                      <CiSearch className="w-[18px] h-[18px] text-gray-500 cursor-pointer" />
                    </div>
                  </div>
                </div>
                <div className='w-[34px] h-[34px] rounded-[6px] border border-[#E5E7EB] p-[7px] gap-[10px] bg-white'>
                  <div className='w-[20px] h-[20px]' onClick={toggleDropdown}>
                    <FaSliders className='w-[16.67px] h-[15.83px] text-[#36678E] cursor-pointer' />
                  </div>


                </div>
              </div>
            </div>
          </div>
          {/* Table Section */}

          {isDataEmpty ? (
            <div class="absolute top-1/2 left-1/2 flex items-center justify-center text-[#6B7280] font-semibold text-lg">No results found for your search. </div>

          ) : (
            <div className={` table flex-grow w-full md:w-${tableWidth} h-full flex flex-col items-center px-2 pt-2 md:px-4 md:pt-4 justify-center`}>

              <div className={`w-full md:w-${tableWidth} border border-gray-300 rounded-tl-lg rounded-tr-lg overflow-y-auto scrollbar-blue max-h-[72dvh] bg-gray-400`}>
                <table className="md:w-full  ">
                  <thead className='bg-[#F9FAFB] font-Inter'>
                    <tr className='uppercase text-left font-medium text-md font-Inter border-b border-gray-300'>
                      <th className="px-4 py-2 sm:h-11 text-[#6B7280] md:text-sm" onClick={() => handleSort('fileName')}>
                        <div className='flex items-center'>
                          <span className=' whitespace-nowrap'>Uploaded By</span>
                          {sortColumn === 'uploaded-by' && sortOrder === 'asc' && (
                            <IoIosArrowDown className="  ml-2 " />
                          )}
                          {sortColumn === 'uploaded-by' && sortOrder === 'desc' && (
                            <IoIosArrowUp className='ml-2' />
                          )}
                        </div>
                      </th>
                      <th className="px-4 py-2 sm:h-11 text-[#6B7280] md:text-sm" onClick={() => handleSort('fileName')}>
                        <div className='flex items-center'>
                          <span className='whitespace-nowrap'>File Name</span>
                          {sortColumn === 'fileName' && sortOrder === 'asc' && (
                            <IoIosArrowDown className="  ml-2 " />
                          )}
                          {sortColumn === 'fileName' && sortOrder === 'desc' && (
                            <IoIosArrowUp className='ml-2' />
                          )}
                        </div>
                      </th>
                      <th className="px-4 py-2 sm:h-11 text-[#6B7280] md:text-sm" onClick={() => handleSort('fileSize')}>
                        <div className='flex items-center whitespace-nowrap'>
                          File Size
                          {sortColumn === 'fileSize' && sortOrder === 'asc' && (
                            <IoIosArrowDown className="ml-2" />
                          )}
                          {sortColumn === 'fileSize' && sortOrder === 'desc' && (
                            <IoIosArrowUp className='ml-2' />
                          )}
                        </div>
                      </th>
                      <th className="px-4 py-2 sm:h-11 text-[#6B7280] md:text-sm" onClick={() => handleSort('fileUploadTime')}>
                        <div className='flex items-center whitespace-nowrap'>
                          File Upload Time
                          {sortColumn === 'fileUploadTime' && sortOrder === 'asc' && (
                            <IoIosArrowDown className="ml-2" />
                          )}
                          {sortColumn === 'fileUploadTime' && sortOrder === 'desc' && (
                            <IoIosArrowUp className='ml-2' />
                          )}
                        </div>
                      </th>
                      <th className="px-4 py-2 sm:h-11 text-[#6B7280] md:text-sm" onClick={() => handleSort('fileUploadTime')}>
                        <div className='flex items-center whitespace-nowrap'>
                          Data Source Link
                          {sortColumn === 'data-source-link' && sortOrder === 'asc' && (
                            <IoIosArrowDown className="ml-2" />
                          )}
                          {sortColumn === 'data-source-link' && sortOrder === 'desc' && (
                            <IoIosArrowUp className='ml-2' />
                          )}
                        </div>
                      </th>
                      <th className="px-4 py-2 sm:h-11 text-[#6B7280] md:text-sm" onClick={() => handleSort('fileUploadTime')}>
                        <div className='flex items-center whitespace-nowrap'>
                          Action

                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="overflow-x-auto overflow-y-scroll font-normal text-[#18191F]">
                    {currentData.map((item, index) => {
                      return (
                        <tr key={index} className={index % 2 === 1 ? 'bg-[#F9FAFB]' : 'bg-white'}>
                          <td className="px-4 sm:h-11 py-2 md:text-sm ">{item.dataSourcedByPerson}</td>
                          <td className="px-4 sm:h-11 py-2 md:text-sm ">{item.fileName}</td>
                          <td className="px-4 sm:h-11 py-2 md:text-sm ">{item.fileSize}</td>
                          <td className="px-4 sm:h-11 py-2 md:text-sm ">{formatUploadTime(item.fileUploadTime)}</td>
                          <td className="px-4 sm:h-11 py-2 md:text-sm ">{item.dataSourceLink}</td>
                          <td className="px-4 sm:h-11 py-2 md:text-sm ">
                            <button
                              onClick={() => copyToClipboard(item.dataSourceLink, index)}
                              className="text-[#36678E] border shadow-sm rounded-md px-3 py-1 hover:bg-gray-100 focus:outline-none"
                            >
                              {copyStatuses[index] ? copyStatuses[index] : 'Copy'}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className={`flex-grow flex w-full md:w-${tableWidth} h-[50px] p-3 items-center border-t-0 rounded-tl-none rounded-tr-none rounded-bl-lg rounded-br-lg border`}>
                <Pagination
                  active={currentPage}
                  setActive={setCurrentPage}
                  totalPages={totalPages}
                  itemsPerPage={itemsPerPage}
                  handleItemsPerPageChange={handleItemsPerPageChange}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>

  );
};

export default List;
